// 订单接口
import axios from 'utils/axios'
import Qs from 'qs'
// 获取订单加购一定金额会有更佳优惠券提示  /coupon/baseInfo/getExtraCoupon
export const getExtraCoupon = (orderAmount) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-coupon/coupon/baseInfo/getExtraCoupon';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                //参数列表
                params: { orderAmount: orderAmount }
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//订单返回 优惠券 getMostExcellentCouponRemind
export const getMostExcellentCouponRemind = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-coupon/coupon/baseInfo/getMostExcellentCouponRemind';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            // data:Qs.stringify(datas)
            data:datas
        })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 购物车获取优惠券  优惠券多入口展示接口  http://39.98.114.1:8001/api/blade-coupon/coupon/baseInfo/getCurrentMemberStoreCouponShopping
export const getCurrentMemberStoreCouponShopping=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-coupon/coupon/baseInfo/getCurrentMemberStoreCouponShopping';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//   我的订单 get
export const OrderList = (params) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/page';
        // axios.get(url,{params:params})
        axios.get(url, {
                params: params
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 积分兑换订单列表 /blade-order/pointsorder/list
export const PointsOrderList = (params) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/pointsorder/list';
        // axios.get(url,{params:params})
        axios.get(url, {
                params: params
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 积分订单确认收货  /blade-order/pointsorder/take
export const SureOrder = (id) => {
    return new Promise((resolve, reject) => {
        let url = 'api/blade-order/pointsorder/take';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                //参数列表
                params: { id: id },
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 积分订单详情   /pointsorder/detail  
// 查询积分订单详情
// status  1.待支付,2.待发货,3.待收货,4.已完成,5.已取消
// status==2||status==3||status==4  时是已经支付状态
export const PointsOrderDetail = (params) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/pointsorder/detail';
        // axios.get(url,{params:params})
        axios.get(url, {
                params: params
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 售后订单表接口   /blade-order/refund/list
export const RefundList = (params) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/refund/list';
        // axios.get(url,{params:params})
        axios.get(url, {
                params: params
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 获取单个订单详情页接口   /blade-order/order/detail  get
export const OrderDetail = (data) => {
        return new Promise((resolve, reject) => {
            let url = '/api/blade-order/order/detail';
            axios.get(url, {
                    //请求头配置  
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    //参数列表
                    params: { id: data },

                })
                .then((res) => {
                    var result = res;
                    return resolve(result)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
    //订单操作流程接口   /blade-order/orderlog/timeList  get
export const TimeList = (orderId) => {
    return new Promise((resolve, reject) => {
        let url = 'api/blade-order/orderlog/timeList';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                //参数列表
                params: { orderId: orderId },
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 订单设置详情 /blade-order/ordersite/detail   get
export const OrdersiteDetail = (type) => {
    return new Promise((resolve, reject) => {
        let url = 'api/blade-order/ordersite/detail';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                //参数列表
                params: { type: type },
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 删除订单   /blade-order/order/remove   post
export const removeOrder = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/remove ';
        axios({
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 取消订单接口  /blade-order/order/cancel    post
export const cancelOrder = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/cancel ';
        axios({
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 确认收货接口   /blade-order/order/confirm    post  传订单id     ids：
export const SureReceiveOrder = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/confirm ';
        axios({
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 申请售后
// 普通订单（子订单提交退单 /blade-order/refund/referOrderDetail） post
export const referOrderDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/refund/referOrderDetail';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 最近购买   列表页   http://localhost:8090/api/blade-order/order/oftenBuy
export const oftenBuy = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/oftenBuy';
        axios({
                url: url,
                method: "post",
                data: Qs.stringify(datas)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//  活动订单（总订单提交退单 /blade-order/refund/referOrder）
export const referOrder = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/refund/referOrder';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// 图片上传接口 /blade-resource/oss/endpoint/put-file   POST
export const putFile = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-resource/oss/endpoint/put-file';
        axios({
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 获取评价标签数据 /blade-order/evaluationlabel/list
export const EvaluateList = () => {
        return new Promise((resolve, reject) => {
            let url = '/api/blade-order/evaluationlabel/list';
            axios.get(url, {
                    //请求头配置  
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                })
                .then((data) => {
                    var result = data;
                    return resolve(result)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
    // 新增订单评价   /blade-order/evaluate/save
export const SaveEvaluate = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/evaluate/save';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 退货单详情表  /blade-order/refund/detail   get     orderId
export const RefundDetail = (backId) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/refund/detail';
        // axios.get(url,{params:params})
        axios.get(url, {
                params: {
                    id: backId
                }
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//撤销退货申请  post   /blade-order/refund/cancel
export const RefundCancel = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/refund/cancel';
        axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 检查是否有售后  /blade-order/refund/check
export const CheckRefund = (params) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/refund/check';
        // axios.get(url,{params:params})
        axios.get(url, {
                params: params
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 根据物流单号查询物流   /blade-order/delivery/detail
export const GetLog = (deliveryId) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/delivery/detail';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                //参数列表
                params: { id: deliveryId },

            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 积分查询物流  /blade-order/delivery/selectLogistics   get
export const GetPointLog = (params) => {
        return new Promise((resolve, reject) => {
            let url = '/api/blade-order/delivery/selectLogistics';
            axios.get(url, {
                    //请求头配置  
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    //参数列表
                    params: params,

                })
                .then((data) => {
                    var result = data;
                    return resolve(result)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
    // 根据订单id下载  根据订单id下载药检报告接口：接口地址：/gsp/download/download-product-inspection-report-by-order-id
    // 请求方式：get
    // 请求参数：orderId
export const GetOrderReport = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-gsp/gsp/download/download-product-inspection-report-by-order-id';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                responseType: 'blob',
                //参数列表
                params: {
                    orderId: datas
                },

            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 2、根据订单明细下载药检报告接口：
// 接口地址：/gsp/download/download-product-inspection-report-by-order-detail
// 请求方式：get
// 请求参数：orderDetailId、goodNum
export const GetOrderDetailReport = (orderDetailId, goodNum) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-gsp/gsp/download/download-product-inspection-report-by-order-detail';
        axios.get(url, {
                //请求头配置  
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                responseType: 'blob',
                //参数列表
                params: {
                    orderDetailId: orderDetailId,
                    goodNum: goodNum
                },

            })
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//   再来一单 /blade-order/order/againOrder  post id
export const AgainOrder = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/againOrder';
        axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 订单数量   /blade-order/order/orderCount
export const PostorderCount = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/order/orderCount';
        axios.post(url)
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 填写退货信息   /blade-order/reship/submit   post   { "logi": "顺丰", "logiNo": "1232444", "refundId": 1321678544526200834 }

export const ReShip = (ReShipData) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/reship/submit';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: ReShipData
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


// /blade-system/shippingaddress/detail    get   无参数
export const shippingDetail = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-system/shippingaddress/detail';
        axios.get(url)
            .then((data) => {
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}





// 获取订单邮费设置信息   /blade-order/freightsite/list   get
export const freightList = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/freightsite/list';
        axios.get(url)
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


//  导入exel文件接口
export const importPurchase = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/purchase/importPurchase';
        axios({
                headers: {
                    'Content-Type': "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW", //格式转换为binary
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//  导入文件商品列表接口
export const getProList = () => {
        return new Promise((resolve, reject) => {
            let url = '/api/blade-order/purchase/list';
            axios.get(url)
                .then((data) => {
                    var result = data;
                    return resolve(result)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
    //  修改导入文件商品数量接口及替换导入商品记录接口
    //传入实例[{"quantity":10,"productId":1},{"quantity":10,"productId":1}]
    // "quantity":10,"productId":1
export const UpdateProNum = (data) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/purchase/update';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: JSON.stringify(data)
            })
            .then((res) => {
                var result = res;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}



// 删除导入商品接口  post   传ids
export const DelExportProduct = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/purchase/remove';
        axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 新增导入商品接口  post   传ids
export const SaveExportProduct = (ids) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/purchase/save';
        axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                url: url,
                method: "post",
                data: Qs.stringify(ids)
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 下载报货清单接口   post
// /export-purchase
export const ExportPurchase = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/purchase/export-purchase';
        axios.get(url, {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                responseType: 'blob', //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//加入购物车   post请求
// 传入实例[{"isRecentExpiration":0,"quantity":10,"productId":1},{"isRecentExpiration":1,"quantity":10,"productId":1}]
export const AddPurchaseSaveCart = (data) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/ordercart/purchaseSave';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: JSON.stringify(data)
            })
            .then((res) => {
                var result = res;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}